export default {
  pt: {
    login:
      "Faça login com seu IBMid no popup. Caso ele não tenha aberto, verifique as permissões do browser.",
    aceleratingIdeasTopic: "Acelerando ideias para resultados",
    aceleratingIdeasText: [
      "Aqui, trabalhamos com parceiros e clientes para impulsionar o progresso de seus negócios por meio de colaboração, engajamentos e workshops.",
      "Oferecemos soluções para diversos setores que buscam automatizar e otimizar suas operações e o atendimento ao cliente.",
    ],
    aceleratingIdeasPSTitle: "Nosso diferencial:",
    aceleratingIdeasPSText: [
      "Atendemos todos os seguimentos de clientes, parceiros e áreas da IBM.",
      "Ajudamos o time de Ecossistema no recrutamento de novos parceiros.",
      "Promovemos um aumento no consumo das plataformas/produtos IBM.",
    ],
    howWeDoItTitle: "Experiências",
    howWeDoItTopic: "Como nós fazemos",
    howWeDoItText:
      "Despertamos o potencial dos clientes, desenvolvendo experiências imersivas e soluções personalizadas com a tecnologia da IBM, para impulsioná-los rapidamente em sua jornada.",
    howWeDoItTab1: "Exploração de casos de uso",
    howWeDoItTab1Text:
      "Curioso para saber quais padrões de implantação estamos vendo enquanto trabalhamos com nossos clientes em todos os setores? Vamos nos inspirar em outras pessoas que enfrentaram problemas semelhantes. Nossas sessões de exploração do setor são conduzidas por nossos especialistas do setor que têm experiência em primeira mão na identificação de desafios, descoberta de oportunidades, abordagem ao cerne do problema e projeto e implementação de soluções.",
    howWeDoItTab2: "Briefings de tecnologia",
    howWeDoItTab2Text:
      "Nossos briefings de tecnologia oferecem uma visão geral das últimas inovações e soluções IBM, ajudando você a entender como elas podem resolver seus desafios e impulsionar o crescimento do seu negócio.",
    howWeDoItTab3: "Workshops de cocriação",
    howWeDoItTab3Text:
      "Nossas sessões colaborativas aproveitam o Enterprise Design Thinking da IBM para ajudá-lo a descobrir novas oportunidades e resolver desafios críticos de negócios.",
    howWeDoItTab4: "Sessões com especialistas",
    howWeDoItTab4Text:
      "Estas sessões pré e pós-implementação oferecem acesso direto a especialistas que ajudam você a dimensionar suas soluções, oferecem perspectivas e muito mais.",
    howWeDoItTab5: "Benefícios para clientes e parceiros",
    howWeDoItTab5Text:
      "Nossa abordagem de desenvolvimento acelera o processo, reduz a incerteza e o custo do projeto, e ainda permite a reutilização de protótipos em outros projetos.",
    whatWeDoTitle: "Demonstrações e ativos",
    whatWeDoTopic: "O que nós fazemos",
    whatWeDoText:
      "Nossa equipe desenvolve demos e assets para demonstrar o valor de nossas tecnologias aplicadas aos problemas de negócios dos clientes. Com prototipagem de alta fidelidade, oferecemos soluções escaláveis para agilizar suas vendas.",
    whatWeDoTopic1Title: "Assets",
    whatWeDoTopic1Text:
      "Desenvolvemos soluções para os problemas apresentados pelo cliente, por meio de protótipos, POCs e MVPs.",
    whatWeDoTopic2Title: "Demos",
    whatWeDoTopic2Text:
      "As demos mostram a tecnologia de uma forma tangível e aplicada ao contexto de um uso.",
    whatWeDoTopic3Title: "Ativações",
    whatWeDoTopic3Text:
      "Conheca os beneficios da tecno-logia através de experiências imersivas.",
    whatWeDoButton: "Saiba mais",
    blackHomeBlockText:
      "Explore o melhor da IBM com nossas soluções e experiências imersivas",
    blackHomeBlockButton: "Catálogo",
    meetTheTeamTitle: "Conheça a equipe",
    meetTheTeamTopic: "Vamos inovar juntos!",
    meetTheTeamText:
      "Nossos especialistas possuem uma ampla gama de habilidades e talentos que podem ajudá-lo com suas necessidades.",
    meetTheTeamGridTitle:
      "Impulsione suas oportunidades no Innovation Studio, entre em contato e agende seu evento.",
    meetTheTeamButton: "Entrar em contato",
    catalogTitle: ["Onde o", "Let's Create", "ganha vida"],
    catalogSubtitle:
      "Explore o melhor da IBM com nossas soluções e experiências imersivas.",
    catalogSideTitle: "Catálogo",
    catalogSideFilterTitle: "Filtros",
    catalogSideFilters: ["Tag", "Tag", "Tag"],
    catalogSideCleanFilter: "Limpar filtros",
    catalogSideFilterTechnologies: ["Tecnologias IBM"],
    catalogSearch: "Procure por soluções",
    catalogTabs: ["Asset", "Demo", "Ativação", "Code Snippet"],
    catalogPages: [
      "Próxima página",
      "Última página",
      "Items per page",
      "items",
      "pages",
    ],
    assetSideTitle: "Visão Geral",
    assetSideType: "Tipo",
    assetSideSalesPlay: "Sales Play",
    assetSideCategory: "Categoria",
    assetSideIndustry: "Indústria",
    assetSideTechnologies: "Tecnologias IBM",
    assetSideButton: "Documentação Técnica",
    docTitle: "Documentação técnica",
    architecture: "Arquitetura",
    codeDownload: "Download do código fonte",
    accessDeniedNotification: {
      title: "Acesso negado",
      description: (assetName) =>
        `Você não possui acesso à documentação do ${assetName}. Por favor, entre em contato.`,
    },
  },
  en: {
    login:
      "Login with your IBMid in the popup. If it has not opened, check the browser permissions.",
    aceleratingIdeasTopic: "Accelerating ideas to results",
    aceleratingIdeasText: [
      "Here, we work with partners and customers to drive their business progress through collaboration, engagements and workshops.",
      "We offer solutions for various sectors looking to automate and improve their operations and customer service.",
    ],
    aceleratingIdeasPSTitle: "Our difference:",
    aceleratingIdeasPSText: [
      "We serve all segments of IBM customers, partners and areas.",
      "We help the Ecosystem time in recruiting new partners.",
      "We promote an increase in the consumption of IBM platforms/products.",
    ],
    howWeDoItTitle: "Experiences",
    howWeDoItTopic: "How We Do It",
    howWeDoItText:
      "We unlock customers' potential by developing immersive experiences and personalized solutions powered by IBM technology to quickly propel them along their journey.",
    howWeDoItTab1: "Exploring use cases",
    howWeDoItTab1Text:
      "Curious about what deployment patterns we’re seeing as we work with our clients across industries? Let’s get inspired by others who have faced similar issues. Our industry exploration sessions are led by our industry experts who have first-hand experience in identifying challenges, discovering opportunities, getting to the heart of the problem, and designing and implementing solutions.",
    howWeDoItTab2: "Technology Briefings",
    howWeDoItTab2Text:
      "Our technology briefings provide an overview of the latest IBM innovations and solutions, helping you understand how they can solve your challenges and drive your business growth.",
    howWeDoItTab3: "Co-creation workshops",
    howWeDoItTab3Text:
      "Our collaborative sessions leverage IBM's Enterprise Design Thinking to help you discover new opportunities and solve critical business challenges.",
    howWeDoItTab4: "Sessions with experts",
    howWeDoItTab4Text:
      "Our collaborative sessions leverage IBM's Enterprise Design Thinking to help you discover new opportunities and solve critical business challenges.",
    howWeDoItTab5: "Benefits for customers and partners",
    howWeDoItTab5Text:
      "Our development approach speeds up the process, reduces project uncertainty and cost, and even allows the reuse of prototypes in other projects.",
    whatWeDoTitle: "Demonstrations and actions",
    whatWeDoTopic: "What we do",
    whatWeDoText:
      "Our team develops demos and assets to demonstrate the value of our technologies applied to customers' business problems. With high-fidelity prototyping, we offer scalable solutions to speed your sales.",
    whatWeDoTopic1Title: "Assets",
    whatWeDoTopic1Text:
      "We develop solutions to problems presented by the client, through prototypes, POCs and MVPs.",
    whatWeDoTopic2Title: "Demos",
    whatWeDoTopic2Text:
      "Demos show the technology in a tangible way and applied to the context of a use.",
    whatWeDoTopic3Title: "Activations",
    whatWeDoTopic3Text:
      "Discover the benefits of technology through immersive experiences.",
    whatWeDoButton: "Take a look",
    blackHomeBlockText:
      "Explore the best of IBM with our immersive solutions and experiences",
    blackHomeBlockButton: "Catalog",
    meetTheTeamTitle: "Meet the team",
    meetTheTeamTopic: "Let's innovate together!",
    meetTheTeamText:
      "Our in-house experts have a wide range of skills and talents that can help you with your needs.",
    meetTheTeamGridTitle:
      "Boost your opportunities at Innovation Studio, get in touch and schedule your event.",
    meetTheTeamButton: "Get in touch",
    catalogTitle: ["Where", "Let's Create", "comes to life"],
    catalogSubtitle:
      "Explore the best of IBM with our immersive solutions and experiences.",
    catalogSideTitle: "Catalog",
    catalogSideFilterTitle: "Filters",
    catalogSideFilters: ["Tag", "Tag", "Tag"],
    catalogSideCleanFilter: "Clear filters",
    catalogSideFilterTechnologies: ["IBM Technologies"],
    catalogSearch: "Search for solutions",
    catalogTabs: ["Asset", "Demo", "Activation", "Code Snippet"],
    catalogPages: [
      "Next page",
      "Last page",
      "Items per page",
      "items",
      "pages",
    ],
    assetSideTitle: "Overview",
    assetSideType: "Type",
    assetSideSalesPlay: "Game of sales",
    assetSideCategory: "Category",
    assetSideIndustry: "Industry",
    assetSideTechnologies: "IBM Technologies",
    assetSideButton: "Technical Documentation",
    docTitle: "Technical Documentation",
    architecture: "Architecture",
    codeDownload: "Source code download",
    accessDeniedNotification: {
      title: "Access Denied",
      description: (assetName) =>
        `You don't have access to ${assetName}'s documentation. Please contact us.`,
    },
  },
  es: {
    login:
      "Inicie sesión con su IBMid en la ventana emergente.Si no se ha abierto, consulte los permisos del navegador.",
    aceleratingIdeasTopic: "Acelerando ideas hacia resultados",
    aceleratingIdeasText: [
      "Aquí trabajamos con socios y clientes para impulsar el progreso de su negocio a través de colaboración, compromisos y talleres.",
      "Ofrecemos soluciones para diversos sectores que buscan automatizar y mejorar sus operaciones y servicio al cliente.",
    ],
    aceleratingIdeasPSTitle: "Nuestra diferencia:",
    aceleratingIdeasPSText: [
      "Atendemos a todos los segmentos de clientes, socios y áreas de IBM.",
      "Ayudamos al ecosistema a reclutar nuevos socios.",
      "Promovemos un aumento en el consumo de plataformas/productos IBM.",
    ],
    howWeDoItTitle: "Experiencias",
    howWeDoItTopic: "Cómo lo hacemos",
    howWeDoItText:
      "Desbloqueamos el potencial de los clientes mediante el desarrollo de experiencias inmersivas y soluciones personalizadas impulsadas por la tecnología de IBM para impulsarlos rápidamente en su viaje.",
    howWeDoItTab1: "Explorando casos de uso",
    howWeDoItTab1Text:
      "¿Tiene curiosidad por saber qué patrones de implementación estamos viendo mientras trabajamos con nuestros clientes en todas las industrias? Inspirémonos en otros que han enfrentado problemas similares. Nuestras sesiones de exploración de la industria están dirigidas por nuestros expertos de la industria que tienen experiencia de primera mano en la identificación de desafíos. , descubriendo oportunidades, acercándonos al corazón del problema y diseñando e implementando soluciones.",
    howWeDoItTab2: "Reuniones informativas sobre tecnología",
    howWeDoItTab2Text:
      "Nuestras sesiones informativas sobre tecnología brindan una descripción general de las últimas innovaciones y soluciones de IBM, lo que lo ayuda a comprender cómo pueden resolver sus desafíos e impulsar el crecimiento de su negocio.",
    howWeDoItTab3: "Talleres de cocreación",
    howWeDoItTab3Text:
      "Nuestras sesiones colaborativas aprovechan el Enterprise Design Thinking de IBM para ayudarle a descubrir nuevas oportunidades y resolver desafíos empresariales críticos",
    howWeDoItTab4: "Sesiones con expertos",
    howWeDoItTab4Text:
      "Estas sesiones previas y posteriores a la implementación le brindan acceso directo a expertos que lo ayudan a escalar sus soluciones, ofrecer perspectivas y más.",
    howWeDoItTab5: "Beneficios para clientes y socios",
    howWeDoItTab5Text:
      "Nuestro enfoque de desarrollo acelera el proceso, reduce la incertidumbre y el costo del proyecto e incluso permite la reutilización de prototipos en otros proyectos.",
    whatWeDoTitle: "Qué hacemos Demos y Activos",
    whatWeDoTopic: "Qué hacemos",
    whatWeDoText:
      "Nuestro equipo desarrolla demostraciones y activos para demostrar el valor de nuestras tecnologías aplicadas a los problemas comerciales de los clientes. Con prototipos de alta fidelidad, ofrecemos soluciones escalables para acelerar sus ventas",
    whatWeDoTopic1Title: "Activo",
    whatWeDoTopic1Text:
      "Desarrollamos soluciones a problemas presentados por el cliente, a través de prototipos, POCs y MVPs.",
    whatWeDoTopic2Title: "Demostración",
    whatWeDoTopic2Text:
      "Las demostraciones muestran la tecnología de forma tangible y aplicada al contexto de un uso.",
    whatWeDoTopic3Title: "Activaciones",
    whatWeDoTopic3Text:
      "Descubre los beneficios de la tecnología a través de experiencias inmersivas.",
    whatWeDoButton: "Sepa mas",
    blackHomeBlockText:
      "Explore lo mejor de IBM con nuestras soluciones y experiencias inmersivas",
    blackHomeBlockButton: "Catalogo",
    meetTheTeamTitle: "Conocer al equipo",
    meetTheTeamTopic: "¡Innovemos juntos!",
    meetTheTeamText:
      "Nuestros expertos tienen una amplia gama de habilidades y talentos que pueden ayudarle con sus necesidades.",
    meetTheTeamGridTitle:
      "Impulsa tus oportunidades en Innovation Studio, ponte en contacto y programa tu evento.",
    meetTheTeamButton: "Entrar en contacto",
    catalogTitle: ["Donde", "Vamos a crear", "cobra vida"],
    catalogSubtitle:
      "Explore lo mejor de IBM con nuestras soluciones y experiencias inmersivas.",
    catalogSideTitle: "Catalogo",
    catalogSideFilterTitle: "Filtros",
    catalogSideFilters: ["Etiqueta", "Etiqueta", "Etiqueta"],
    catalogSideCleanFilter: "Borrar filtros",
    catalogSideFilterTechnologies: ["Tecnologías IBM"],
    catalogSearch: "Búsqueda de soluciones",
    catalogTabs: [
      "Activo",
      "Demostración",
      "Activación",
      "Fragmento de código",
    ],
    catalogPages: [
      "Página siguiente",
      "Última página",
      "Elementos por página",
      "elementos",
      "páginas",
    ],
    assetSideTitle: "Descripción general",
    assetSideType: "Tipo",
    assetSideSalesPlay: "Juego de ventas",
    assetSideCategory: "Categoría",
    assetSideIndustry: "Industria",
    assetSideTechnologies: "Tecnologías IBM",
    assetSideButton: "Documentación técnica",
    docTitle: "Documentación Técnica",
    architecture: "Arquitectura",
    codeDownload: "Descarga del código fuente",
    accessDeniedNotification: {
      title: "Acceso denegado",
      description: (assetName) =>
        `No tienes acceso a la documentación de ${assetName}. Por favor contáctenos.`,
    },
  },
};
