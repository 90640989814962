import React, { useEffect, useState } from "react";

import { useGlobalState } from "../../hooks/globalState";
import { useNavigate } from "react-router-dom";

import { Column } from "@carbon/react";
import { Edit, ErrorOutline } from "@carbon/icons-react";

export default function AssetCard({ asset }) {
  const navigate = useNavigate();
  const { language, categories, isAdmin } = useGlobalState();
  const [category, setCategory] = useState([]);
  const [industry, setIndustry] = useState({ name: "", id: 0 });
  const [salesplay, setSalesplay] = useState({ name: "", id: 0 });

  useEffect(() => {
    setCategory(
      categories.category.filter((c) => asset.category.includes(c.id))
    );
    setIndustry(categories.industry.find((i) => asset.industry === i.id));
    setSalesplay(categories.sales_play.find((s) => asset.sales_play === s.id));
  }, [asset]);

  return (
    <Column sm={2} md={4} lg={4} xlg={4} style={{ marginBottom: "1rem" }}>
      <div
        className="assetEntry"
        onClick={() => {
          navigate(`/${language}/asset/${asset.name}`);
        }}
      >
        <div className="assetImg">
          <img src={asset.image} />
        </div>
        <div className="assetInfo">
          <div className="assetName">{asset.name}</div>
          {isAdmin && (
            <div className="admin-controls">
              <Edit
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/${language}/register/${asset.id}`);
                }}
                className="icon"
              />
              {asset.visible === 1 && <ErrorOutline className="icon" />}
            </div>
          )}
          <div className="assetCategories">
            {category.map((c) => (
              <p key={`asset-cat-${c.id}`}>{c.name}</p>
            ))}
            <p key={`asset-ind-${industry.id}`}>{industry.name}</p>
            <p key={`asset-salp-${salesplay.id}}`}>{salesplay.name}</p>
          </div>
        </div>
      </div>
    </Column>
  );
}
