import assetImg from "../imgs/asset.svg";
import demo from "../imgs/demo.svg";
import activation from "../imgs/activation.svg";
import snippet from "../imgs/snippet.svg";

export const markdownKeys = {
  pt: {
    documentationKey: "documentation",
    descriptionKey: "description",
  },
  en: {
    documentationKey: "documentation_en",
    descriptionKey: "description_en",
  },
  es: {
    documentationKey: "documentation_es",
    descriptionKey: "description_es",
  },
};

export const types = [
  { image: assetImg, name: "asset" },
  { image: demo, name: "demo" },
  { image: snippet, name: "snippet" },
  { image: activation, name: "activation" },
];
