import React, { useEffect, useState } from "react";

import { useGlobalState } from "../../hooks/globalState";
import { useNavigate, useSearchParams } from "react-router-dom";

import "./style.css";

import Header from "../../Components/Header";
import AssetCard from "../../Components/AssetCard";

import header1 from "../../imgs/header1.png";
import header2 from "../../imgs/header2.png";
import innovation from "../../imgs/innovation.png";

import languageConfig from "../../helpers/languageConfig";

import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  Grid,
  Search,
  Select,
  SelectItem,
  Tab,
  TabList,
  Tabs,
  Tag,
  Pagination,
} from "@carbon/react";

export default function Catalog() {
  const navigate = useNavigate();
  const {
    isMobile,
    language,
    assets,
    filteredAssets,
    setFilteredAssets,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    categories,
    isAdmin,
  } = useGlobalState();

  const [searchParams, setSearchParams] = useSearchParams();

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(startIndex + itemsPerPage);
  const [selectedIndustry, setSelectedIndustry] = useState(-1);
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [selectedKind, setSelectedKind] = useState(1);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setStartIndex((currentPage - 1) * itemsPerPage);
  }, [currentPage]);

  useEffect(() => {
    setEndIndex(startIndex + itemsPerPage);
  }, [startIndex]);

  function getParamCategory(label, defaultValue) {
    let obj = categories[label].find(
      (c) => c.name.toLowerCase() == searchParams.get(label).toLowerCase()
    );
    return obj ? obj.id : defaultValue;
  }

  useEffect(() => {
    if (categories) {
      if (searchParams.get("kind"))
        setSelectedKind(getParamCategory("kind", 1));
      if (searchParams.get("category"))
        setSelectedCategory(getParamCategory("category", -1));
      if (searchParams.get("industry"))
        setSelectedIndustry(getParamCategory("industry", -1));
    }
  }, [categories]);

  useEffect(() => {
    if (assets) {
      setFilteredAssets(
        assets
          .filter(
            (a) =>
              search === "" ||
              a.name.toLowerCase().includes(search.toLowerCase())
          )
          .filter(
            (a) => selectedIndustry === -1 || a.industry == selectedIndustry
          )
          .filter(
            (a) =>
              selectedCategory === -1 || a.category.includes(selectedCategory)
          )
      );
    }
    setCurrentPage(1);
  }, [selectedKind, selectedIndustry, selectedCategory, search]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    const observer = new IntersectionObserver((entries) => {
      // Loop over the entries
      entries.forEach((entry) => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation class
          if (entry.target.classList.contains("animateRight")) {
            entry.target.classList.remove("animateRight");
            entry.target.classList.add("animated-right");
          } else if (entry.target.classList.contains("animateLeft")) {
            entry.target.classList.remove("animateLeft");
            entry.target.classList.add("animated-left");
          }
        }
      });
    });
    const squares = document.querySelectorAll(".animateLeft, .animateRight");
    squares.forEach((element) => observer.observe(element));
  }, []);

  return (
    <div id="catalogContent">
      <Header />
      <div className="catalogBlock" id="firstCatalogBlock">
        <div id="breadCrumb">
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem
              className="breadcrumbWhiteText"
              onClick={() => navigate(`/${language}/home`)}
            >
              Home
            </BreadcrumbItem>
            <BreadcrumbItem
              className="breadcrumbWhiteText"
              onClick={() => navigate(`/${language}/catalog`)}
            >
              Catalog
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <Grid style={{ height: "100%", margin: 0, paddingTop: "2rem" }}>
          <Column sm={4} md={8} lg={1} xlg={1}></Column>
          <Column
            sm={4}
            md={8}
            lg={8}
            xlg={8}
            style={
              isMobile
                ? {}
                : {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }
            }
          >
            <p
              className={
                isMobile ? "firstCatalogTitleMobile" : "firstCatalogTitle"
              }
            >
              {languageConfig[language].catalogTitle[0]}
            </p>
            <p
              className={
                isMobile
                  ? "firstCatalogTitleMobile gradient"
                  : "firstCatalogTitle gradient"
              }
            >
              {languageConfig[language].catalogTitle[1]}
            </p>
            <p
              className={
                isMobile ? "firstCatalogTitleMobile" : "firstCatalogTitle"
              }
            >
              {languageConfig[language].catalogTitle[2]}
            </p>
            <p
              className={
                isMobile ? "firstCatalogTextMobile" : "firstCatalogText"
              }
            >
              {languageConfig[language].catalogSubtitle}
            </p>
          </Column>
          <Column
            sm={4}
            md={8}
            lg={7}
            xlg={7}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                paddingTop: "2rem",
                display: "flex",
                height: "90%",
                width: "80%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  zIndex: 1,
                  position: "relative",
                  objectFit: "contain",
                  width: "90%",
                  alignSelf: "start",
                }}
                src={header1}
              />
              <img
                style={{
                  objectFit: "contain",
                  zIndex: 0,
                  width: "80%",
                  alignSelf: "end",
                  marginTop: "-2rem",
                }}
                src={header2}
              />
            </div>
          </Column>
          <img
            style={{ padding: "2rem", paddingLeft: "1rem" }}
            src={innovation}
          />
        </Grid>
      </div>
      <Grid id="catalogGrid" style={{ padding: 0 }}>
        <Column
          className="catalogColumn"
          sm={4}
          md={8}
          lg={4}
          xlg={4}
          style={{ backgroundColor: "white" }}
        >
          <div id="catalogFirstColumnContent">
            <div id="catalogFirstColumnTitle">
              {languageConfig[language].catalogSideTitle}
            </div>
            <div id="catalogFisrtColumnFilters">
              <div id="catalogFisrtColumnFilterTitle">
                {languageConfig[language].catalogSideFilterTitle}
              </div>
              <div id="catalogFisrtColumnTags">
                {selectedCategory !== -1 && categories && (
                  <Tag
                    filter
                    type="blue"
                    onClose={() => setSelectedCategory(-1)}
                  >
                    {
                      categories.category.find((c) => c.id == selectedCategory)
                        .name
                    }
                  </Tag>
                )}
                {selectedIndustry !== -1 && categories && (
                  <Tag
                    filter
                    type="blue"
                    onClose={() => setSelectedIndustry(-1)}
                  >
                    {
                      categories.industry.find((c) => c.id == selectedIndustry)
                        .name
                    }
                  </Tag>
                )}
              </div>
              {(selectedCategory !== -1 || selectedIndustry !== -1) && (
                <div
                  id="catalogFisrtColumnClear"
                  onClick={() => {
                    setSelectedCategory(-1);
                    setSelectedIndustry(-1);
                  }}
                >
                  {languageConfig[language].catalogSideCleanFilter}
                </div>
              )}

              <div id="catalogFisrtColumnList">
                <Select
                  light
                  id={`productSelect`}
                  labelText="Indústrias"
                  value={selectedIndustry}
                  onChange={(e) =>
                    setSelectedIndustry(parseInt(e.target.value))
                  }
                >
                  <SelectItem value={-1} text="Todas" />
                  {categories &&
                    categories.industry.map((industry) => (
                      <SelectItem value={industry.id} text={industry.name} />
                    ))}
                </Select>
                <Select
                  light
                  id={`categorySelect`}
                  labelText="Categorias"
                  value={selectedCategory}
                  onChange={(e) =>
                    setSelectedCategory(parseInt(e.target.value))
                  }
                >
                  <SelectItem value={null} text="Todas" />
                  {categories &&
                    categories.category.map((c) => (
                      <SelectItem value={c.id} text={c.name} />
                    ))}
                </Select>
              </div>
            </div>
          </div>
        </Column>
        <Column
          className="catalogColumn"
          sm={4}
          md={8}
          lg={12}
          xlg={12}
          style={{ backgroundColor: "#f4f4f4" }}
        >
          <div id="assetGridContainer">
            <Search
              light
              placeholder={languageConfig[language].catalogSearch}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {categories && (
              <Tabs
                style={{ paddingTop: "3rem" }}
                selectedIndex={categories.kind.findIndex(
                  (k) => k.id === selectedKind
                )}
              >
                <TabList>
                  {categories.kind.map(
                    (k) =>
                      assets &&
                      assets.some((asset) => asset.kind === k.id) && (
                        <Tab
                          className="catalogTab"
                          onClick={() => setSelectedKind(k.id)}
                          tabIndex={k.id}
                        >
                          {k.name} -{" "}
                          {
                            filteredAssets.filter(
                              (asset) => asset.kind === k.id
                            ).length
                          }
                        </Tab>
                      )
                  )}
                </TabList>
              </Tabs>
            )}

            <Grid>
              {filteredAssets &&
                filteredAssets
                  .filter((asset) => asset.kind === selectedKind)
                  .filter((asset) => isAdmin || asset.visible === 0)
                  .slice(startIndex, endIndex)
                  .map((asset) => <AssetCard asset={asset} />)}
            </Grid>
            {filteredAssets.slice(startIndex, endIndex).length > 0 && (
              <Pagination
                style={{ position: "absolute", bottom: 0, left: 0 }}
                backwardText={languageConfig[language].catalogPages[1]}
                forwardText={languageConfig[language].catalogPages[0]}
                itemsPerPageText={languageConfig[language].catalogPages[2]}
                onChange={(data) => {
                  setCurrentPage(data.page);
                  setItemsPerPage(data.pageSize);
                }}
                page={currentPage}
                pageSize={itemsPerPage}
                pageSizes={[6, 9, 12]}
                size="md"
                totalItems={
                  filteredAssets.filter((asset) => asset.kind === selectedKind)
                    .length
                }
              />
            )}
          </div>
        </Column>
      </Grid>
    </div>
  );
}
