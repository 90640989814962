import React, { useEffect, useState } from "react";

import { useGlobalState } from "../../hooks/globalState";
import { useNavigate } from "react-router-dom";

import useCases from "../../imgs/useCases.png";
import briefings from "../../imgs/briefings.png";
import workshops from "../../imgs/workshops.png";
import specialists from "../../imgs/specialists.png";
import benefits from "../../imgs/benefits.png";

import {
  Grid,
  Column,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@carbon/react";

import "./style.css";
import languageConfig from "../../helpers/languageConfig";

export default function TabPC() {
  const { language } = useGlobalState();
  return (
    <Tabs>
      <TabList aria-label="List of tabs">
        <Tab className="tab">{languageConfig[language].howWeDoItTab1}</Tab>
        <Tab className="tab">{languageConfig[language].howWeDoItTab2}</Tab>
        <Tab className="tab">{languageConfig[language].howWeDoItTab3}</Tab>
        <Tab className="tab">{languageConfig[language].howWeDoItTab4}</Tab>
        <Tab className="tab">{languageConfig[language].howWeDoItTab5}</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <Grid narrow style={{ paddingTop: "1rem" }}>
            <Column sm={4} md={3} lg={4} xlg={4}>
              <div className="tabPanelFirstColumn">
                <div className="tabPanelTitle">
                  {languageConfig[language].howWeDoItTab1}
                </div>
                <div className="tabPanelText">
                  {languageConfig[language].howWeDoItTab1Text}
                </div>
              </div>
            </Column>
            <Column sm={4} md={5} lg={8} xlg={8}>
              <div className="tabPanelImg">
                <img src={useCases} />
              </div>
            </Column>
          </Grid>
        </TabPanel>
        <TabPanel>
          <Grid narrow style={{ paddingTop: "1rem" }}>
            <Column sm={4} md={3} lg={4} xlg={4}>
              <div className="tabPanelFirstColumn">
                <div className="tabPanelTitle">
                  {languageConfig[language].howWeDoItTab2}
                </div>
                <div className="tabPanelText">
                  {languageConfig[language].howWeDoItTab2Text}
                </div>
              </div>
            </Column>
            <Column sm={4} md={5} lg={8} xlg={8}>
              <div className="tabPanelImg">
                <img src={briefings} />
              </div>
            </Column>
          </Grid>
        </TabPanel>
        <TabPanel>
          <Grid narrow style={{ paddingTop: "1rem" }}>
            <Column sm={4} md={3} lg={4} xlg={4}>
              <div className="tabPanelFirstColumn">
                <div className="tabPanelTitle">
                  {languageConfig[language].howWeDoItTab3}
                </div>
                <div className="tabPanelText">
                  {languageConfig[language].howWeDoItTab3Text}
                </div>
              </div>
            </Column>
            <Column sm={4} md={5} lg={8} xlg={8}>
              <div className="tabPanelImg">
                <img src={workshops} />
              </div>
            </Column>
          </Grid>
        </TabPanel>
        <TabPanel>
          <Grid narrow style={{ paddingTop: "1rem" }}>
            <Column sm={4} md={3} lg={4} xlg={4}>
              <div className="tabPanelFirstColumn">
                <div className="tabPanelTitle">
                  {languageConfig[language].howWeDoItTab4}
                </div>
                <div className="tabPanelText">
                  {languageConfig[language].howWeDoItTab4Text}
                </div>
              </div>
            </Column>
            <Column sm={4} md={5} lg={8} xlg={8}>
              <div className="tabPanelImg">
                <img src={specialists} />
              </div>
            </Column>
          </Grid>
        </TabPanel>
        <TabPanel>
          <Grid narrow style={{ paddingTop: "1rem" }}>
            <Column sm={4} md={3} lg={4} xlg={4}>
              <div className="tabPanelFirstColumn">
                <div className="tabPanelTitle">
                  {languageConfig[language].howWeDoItTab5}
                </div>
                <div className="tabPanelText">
                  {languageConfig[language].howWeDoItTab5Text}
                </div>
              </div>
            </Column>
            <Column sm={4} md={5} lg={8} xlg={8}>
              <div className="tabPanelImg">
                <img src={benefits} />
              </div>
            </Column>
          </Grid>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
