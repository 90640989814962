import { WatsonHealthMagnify, Home } from "@carbon/icons-react";

import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import { SideNav, SideNavItems, SideNavLink } from "@carbon/react";

export default function SideMenu({ open }) {
  const navigate = useNavigate();

  return (
    <SideNav expanded={open} isPersistent={false} aria-label="side-navigation">
      <SideNavItems>
        <SideNavLink renderIcon={Home} onClick={() => navigate(`/home`)}>
          Home
        </SideNavLink>
        <SideNavLink
          renderIcon={WatsonHealthMagnify}
          onClick={() => navigate(`/catalog`)}
        >
          Catálogo
        </SideNavLink>
      </SideNavItems>
    </SideNav>
  );
}
