import React, { useEffect, useState } from "react";

import { useGlobalState } from "../../hooks/globalState";
import { useNavigate } from "react-router-dom";

import useCases from "../../imgs/useCases.png";
import briefings from "../../imgs/briefings.png";
import workshops from "../../imgs/workshops.png";
import specialists from "../../imgs/specialists.png";
import benefits from "../../imgs/benefits.png";

import { Grid, Column, Accordion, AccordionItem } from "@carbon/react";

import "./style.css";
import languageConfig from "../../helpers/languageConfig";

export default function AccordionMobile() {
  const { language } = useGlobalState();
  const [accordionOpen, setAccordionOpen] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  return (
    <Accordion>
      <AccordionItem
        open={accordionOpen[0]}
        onClick={() => {
          accordionOpen[0]
            ? setAccordionOpen([false, false, false, false, false])
            : setAccordionOpen([true, false, false, false, false]);
        }}
        title={
          accordionOpen[0] ? (
            <b style={{ fontWeight: "bold" }}>
              {languageConfig[language].howWeDoItTab1}
            </b>
          ) : (
            languageConfig[language].howWeDoItTab1
          )
        }
      >
        <Grid>
          <Column sm={4} md={3} lg={4} xlg={4}>
            <div className="accordionText">
              {languageConfig[language].howWeDoItTab1Text}
            </div>
          </Column>
          <Column sm={4} md={5} lg={8} xlg={8}>
            <div className="accordionImg">
              <img src={useCases} />
            </div>
          </Column>
        </Grid>
      </AccordionItem>
      <AccordionItem
        open={accordionOpen[1]}
        onClick={() => {
          accordionOpen[1]
            ? setAccordionOpen([false, false, false, false, false])
            : setAccordionOpen([false, true, false, false, false]);
        }}
        title={
          accordionOpen[1] ? (
            <b style={{ fontWeight: "bold" }}>
              {languageConfig[language].howWeDoItTab2}
            </b>
          ) : (
            languageConfig[language].howWeDoItTab2
          )
        }
      >
        <Grid>
          <Column sm={4} md={3} lg={4} xlg={4}>
            <div className="accordionText">
              {languageConfig[language].howWeDoItTab2Text}
            </div>
          </Column>
          <Column sm={4} md={5} lg={8} xlg={8}>
            <div className="accordionImg">
              <img src={briefings} />
            </div>
          </Column>
        </Grid>
      </AccordionItem>
      <AccordionItem
        open={accordionOpen[2]}
        onClick={() => {
          accordionOpen[2]
            ? setAccordionOpen([false, false, false, false, false])
            : setAccordionOpen([false, false, true, false, false]);
        }}
        title={
          accordionOpen[2] ? (
            <b style={{ fontWeight: "bold" }}>
              {languageConfig[language].howWeDoItTab3}
            </b>
          ) : (
            languageConfig[language].howWeDoItTab3
          )
        }
      >
        <Grid>
          <Column sm={4} md={3} lg={4} xlg={4}>
            <div className="accordionText">
              {languageConfig[language].howWeDoItTab3Text}
            </div>
          </Column>
          <Column sm={4} md={5} lg={8} xlg={8}>
            <div className="accordionImg">
              <img src={workshops} />
            </div>
          </Column>
        </Grid>
      </AccordionItem>
      <AccordionItem
        open={accordionOpen[3]}
        onClick={() => {
          accordionOpen[3]
            ? setAccordionOpen([false, false, false, false, false])
            : setAccordionOpen([false, false, false, true, false]);
        }}
        title={
          accordionOpen[3] ? (
            <b style={{ fontWeight: "bold" }}>
              {languageConfig[language].howWeDoItTab4}
            </b>
          ) : (
            languageConfig[language].howWeDoItTab4
          )
        }
      >
        <Grid>
          <Column sm={4} md={3} lg={4} xlg={4}>
            <Grid>
              <Column sm={4} md={3} lg={4} xlg={4}>
                <div className="accordionText">
                  {languageConfig[language].howWeDoItTab4Text}
                </div>
              </Column>
              <Column sm={4} md={5} lg={8} xlg={8}>
                <div className="accordionImg">
                  <img src={specialists} />
                </div>
              </Column>
            </Grid>
          </Column>
        </Grid>
      </AccordionItem>
      <AccordionItem
        open={accordionOpen[4]}
        onClick={() => {
          accordionOpen[4]
            ? setAccordionOpen([false, false, false, false, false])
            : setAccordionOpen([false, false, false, false, true]);
        }}
        title={
          accordionOpen[4] ? (
            <b style={{ fontWeight: "bold" }}>
              {languageConfig[language].howWeDoItTab5}
            </b>
          ) : (
            languageConfig[language].howWeDoItTab5
          )
        }
      >
        <Grid>
          <Column sm={4} md={3} lg={4} xlg={4}>
            <div className="accordionText">
              {languageConfig[language].howWeDoItTab5Text}
            </div>
          </Column>
          <Column sm={4} md={5} lg={8} xlg={8}>
            <div className="accordionImg">
              <img src={benefits} />
            </div>
          </Column>
        </Grid>
      </AccordionItem>
    </Accordion>
  );
}
