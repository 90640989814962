import React, { useState } from "react";
import { useGlobalState } from "../../hooks/globalState";
import { Modal, TextInput, InlineLoading } from "@carbon/react";
import api from "../../services/api";

export default function NewUserModal({ open, setOpen }) {
  const { users, setUsers } = useGlobalState();
  const [emails, setEmails] = useState("");
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const emailArr = emails.split(",");
    let newUsers = [];
    for (let email of emailArr) {
      let res = await api.post("/appid/registerUser", { email: email.trim() });
      newUsers.push(res.data);
    }
    setUsers([...users, ...newUsers]);
    setOpen(false);
    setLoading(false);
  }
  return (
    <Modal
      open={open}
      modalHeading={"Insira o IBMid dos novos usuários separados por vírgula"}
      modalLabel={"Cadastrar novos usuários"}
      primaryButtonText={"Enviar"}
      secondaryButtonText={"Cancelar"}
      onRequestClose={() => {
        setOpen(false);
      }}
      onRequestSubmit={handleSubmit}
      preventCloseOnClickOutside
    >
      <div id="new_user_modal">
        <TextInput
          data-modal-primary-focus
          labelText="E-mail"
          placeholder="user@domain.com,user@domain.com,user@domain.com"
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
        />
        {loading ? <InlineLoading /> : null}
      </div>
    </Modal>
  );
}
