import AppID from "ibmcloud-appid-js";
import { monitorAccess } from "./data";

export async function authenticateUser() {
  try {
    let user = checkLogin();
    if (!user) {
      const appId = new AppID();
      await appId.init({
        clientId: "5f3f10af-9a16-4c5e-b402-4d0a730e0531",
        discoveryEndpoint:
          "https://us-south.appid.cloud.ibm.com/oauth/v4/80c4b8ca-b439-457c-935c-a4cc45be5554/.well-known/openid-configuration",
      });
      console.log(appId);
      const tokens = await appId.signin();
      user = {
        ...tokens.idTokenPayload,
        timestamp: Date.now(),
        role: tokens.accessTokenPayload.scope.split(" ").pop(),
      };
      localStorage.setItem("@iis-portal/user", JSON.stringify(user));
      if (user.role !== "adm") monitorAccess("-", user, "Login no portal");
    }
    return user;
  } catch (error) {
    return null;
  }
}

function checkLogin() {
  const user = JSON.parse(localStorage.getItem("@iis-portal/user"));
  if (user && isFromLastDay(user.timestamp)) return user;
}

function isFromLastDay(timestamp) {
  const DAY = 1000 * 60 * 60 * 24;
  const aDayAgo = Date.now() - DAY;
  return timestamp > aDayAgo;
}
