import React, { useState } from "react";
import { useGlobalState } from "../../hooks/globalState";

import {
  Header,
  HeaderGlobalAction,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
} from "@carbon/react";
import { Close, Menu } from "@carbon/icons-react";
import { Theme } from "@carbon/react";
import { useLocation, useNavigate } from "react-router-dom";
import languageConfig from "../../helpers/languageConfig";

import SideMenu from "../SideNav";
import "./style.css";

export default function HeaderComponent() {
  const { isMobile, language, isAdmin } = useGlobalState();

  const [openSidePanel, setOpenSidePanel] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Theme theme="g100">
      <Header aria-label="Assistant Curator">
        {isMobile && (
          <HeaderGlobalAction
            className="navMenu"
            onClick={() => setOpenSidePanel(!openSidePanel)}
          >
            {openSidePanel ? <Close /> : <Menu />}
          </HeaderGlobalAction>
        )}
        <HeaderName
          onClick={() => navigate(`/${language}/home`)}
          prefix="IBM"
          style={{ cursor: "pointer" }}
        >
          Innovation Studio São Paulo
        </HeaderName>
        <HeaderNavigation aria-label="language-bar">
          <HeaderMenuItem
            isActive={location.pathname.includes("home")}
            onClick={() => navigate(`/${language}/home`)}
          >
            Home
          </HeaderMenuItem>
          <HeaderMenuItem
            isActive={location.pathname.includes("catalog")}
            onClick={() => navigate(`/${language}/catalog`)}
          >
            {languageConfig[language].blackHomeBlockButton}
          </HeaderMenuItem>
          <HeaderMenuItem
            onClick={() =>
              window.open(
                "https://spot-cases.innovation-studio-assets.com",
                "_blank"
              )
            }
          >
            SpotCases
          </HeaderMenuItem>
          <HeaderMenuItem
            onClick={() =>
              window.open(
                "https://partner-demo.innovation-studio-assets.com",
                "_blank"
              )
            }
          >
            Soluções de Parceiros
          </HeaderMenuItem>
          {isAdmin && (
            <>
              <HeaderMenuItem
                isActive={location.pathname.includes("/register/new")}
                onClick={() => navigate(`/${language}/register/new`)}
              >
                Cadastrar solução
              </HeaderMenuItem>
              <HeaderMenuItem
                isActive={location.pathname.includes("/groups")}
                onClick={() => navigate(`/${language}/groups`)}
              >
                Grupos de acesso
              </HeaderMenuItem>
            </>
          )}
        </HeaderNavigation>
      </Header>
      <SideMenu open={openSidePanel} />
    </Theme>
  );
}
