import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useGlobalState } from "../hooks/globalState";
import Login from "../Components/Login";

import { authenticateUser } from "../helpers/auth";

const ProtectedPage = () => {
  const { user, setUser, setIsAdmin } = useGlobalState();

  const [loadingLogin, setLoadingLogin] = useState(false);

  useEffect(() => {
    if (!user) login();
  }, []);

  useEffect(() => {
    if (user) setIsAdmin(user.role === "adm");
  }, [user]);

  async function login() {
    setLoadingLogin(true);
    let user = await authenticateUser();
    if (user) setUser(user);
    setLoadingLogin(false);
  }

  return user ? (
    <Outlet />
  ) : (
    <Login loadingLogin={loadingLogin} login={login} />
  );
};

export default ProtectedPage;
