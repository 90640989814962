import api from "../services/api";

export async function getAssets(assets, setAssets, setFilteredAssets) {
  return new Promise((resolve, reject) => {
    if (!assets)
      api
        .get("/assets/select")
        .then(({ data }) => {
          setAssets(data.rows);
          setFilteredAssets(data.rows);
          resolve();
        })
        .catch((e) => reject(`ERROR getting assets: ${e}`));
    else resolve();
  });
}

export async function getCategories(categories, setCategories) {
  return new Promise((resolve, reject) => {
    if (!categories)
      api
        .get("/settings")
        .then(({ data }) => {
          setCategories(data);
          resolve();
        })
        .catch((e) => reject(`ERROR getting categories: ${e}`));
    else resolve();
  });
}

export async function checkAssetAccess(assetId, role) {
  let { data } = await api.post("/assets/download", { assetId, role });
  return data.allow;
}

export async function getUsers(users, setUsers) {
  return new Promise((resolve, reject) => {
    if (!users)
      api
        .get("/appid/users")
        .then(({ data }) => {
          setUsers(data.users);
          resolve();
        })
        .catch((e) => reject(`ERROR getting users: ${e}`));
    else resolve();
  });
}

export async function getRoles(roles, setRoles) {
  return new Promise((resolve, reject) => {
    if (!roles)
      api
        .get("/appid/roles")
        .then(({ data }) => {
          setRoles(data.roles);
          resolve();
        })
        .catch((e) => reject(`ERROR getting roles: ${e}`));
    else resolve();
  });
}

export async function monitorAccess(asset, user, action) {
  await api.post("/monitor", { asset, user, action });
}
