import React, { useEffect, useState } from "react";

import { useGlobalState } from "../../hooks/globalState";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../../Components/Header";

import {
  Button,
  ButtonSet,
  Column,
  Grid,
  TextInput,
  TextArea,
  Select,
  SelectItem,
  MultiSelect,
  Form,
  FilterableMultiSelect,
  Checkbox,
  FileUploader,
} from "@carbon/react";

import api from "../../services/api";
import "./style.css";

export default function Register() {
  const navigate = useNavigate();
  const { assetId } = useParams();

  const { language, assets, setAssets, categories, users, setLoading } =
    useGlobalState();

  const [assetImage, setAssetImage] = useState(null);
  const [assetVideo, setAssetVideo] = useState(null);
  const [assetDocumentation, setAssetDocumentation] = useState(null);
  const [assetSourceCode, setAssetSourceCode] = useState(null);

  // image, source, documentation
  const [asset, setAsset] = useState({
    name: "",
    description: "",
    description_en: "",
    description_es: "",
    kind: 0,
    industry: 0,
    sales_play: 0,
    authors: [],
    visible: false,
    category: [],
    ibm_tech: [],
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    if (assets && assetId) {
      const foundAsset = assets.find((item) => item.id === assetId);
      if (foundAsset) setAsset(foundAsset);
    }
  }, [assets]);

  function updateAssetField(field, value) {
    let tmp = JSON.parse(JSON.stringify(asset));
    tmp[field] = value;
    setAsset(tmp);
  }

  function handleFileUpload(e, field) {
    if (field === "image") setAssetImage(e.target.files[0]);
    else if (field === "video") setAssetVideo(e.target.files[0]);
    else if (field === "documentation")
      setAssetDocumentation(e.target.files[0]);
    else if (field === "source") setAssetSourceCode(e.target.files[0]);
  }

  function handleRemoveFile(field) {
    if (field === "image") setAssetImage(null);
    else if (field === "video") setAssetVideo(null);
    else if (field === "documentation") setAssetDocumentation(null);
    else if (field === "source") setAssetSourceCode(null);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    if (assetImage) formData.append("image", assetImage);
    if (assetVideo) formData.append("video", assetVideo);
    if (assetDocumentation)
      formData.append("documentation", assetDocumentation);
    if (assetSourceCode) formData.append("source", assetSourceCode);

    formData.append("asset", JSON.stringify(asset));
    api
      .post(assetId === "new" ? "/assets/insert" : "/assets/update", formData)
      .then((res) => {
        if (assetId === "new")
          setAssets((oldAssets) => [res.data.asset, ...oldAssets]);
        else
          setAssets((oldAssets) => [
            res.data.asset,
            ...oldAssets.filter((a) => a.id !== asset.id),
          ]);
        navigate(`/${language}/catalog`);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoading(false));
  }

  async function handleRemove() {
    await api.post("assets/remove", {
      id: asset.id,
    });
    setAssets((oldAssets) => oldAssets.filter((g) => g.id !== asset.id));
    navigate(`/${language}/catalog`);
  }

  return (
    <div id="registerContent">
      <Header />
      <div className="content">
        <Grid
          style={{
            height: "100%",
            margin: 0,
            paddingTop: "4rem",
            paddingLeft: "1rem",
            paddingBottom: "2rem",
            backgroundColor: "#262626",
          }}
        >
          <Column sm={4} md={8} lg={10} xlg={10}>
            <p className="assetTitle">
              {assetId === "new" ? "Registrar nova solução" : "Editar solução"}
            </p>
          </Column>
        </Grid>
      </div>
      <Grid id="formGrid" style={{ padding: 0 }}>
        <Column
          sm={4}
          md={8}
          lg={12}
          xlg={16}
          style={{ backgroundColor: "white" }}
          className="formColumn"
        >
          <Form onSubmit={handleSubmit}>
            <TextInput
              className="form-field"
              labelText="Nome"
              value={asset.name}
              name="name"
              onChange={(e) => updateAssetField("name", e.target.value)}
              maxCount={100}
              enableCounter
              required
            />
            <TextArea
              className="form-field"
              labelText="Descrição"
              value={asset.description}
              name="description"
              onChange={(e) => updateAssetField("description", e.target.value)}
              maxCount={500}
              counterMode="character"
              enableCounter
              required
            />
            <TextArea
              className="form-field"
              labelText="Descrição em Inglês"
              value={asset.description_en}
              name="description_en"
              onChange={(e) =>
                updateAssetField("description_en", e.target.value)
              }
              maxCount={500}
              counterMode="character"
              enableCounter
              required
            />
            <TextArea
              className="form-field"
              labelText="Descrição em Espanhol"
              value={asset.description_es}
              name="description_es"
              onChange={(e) =>
                updateAssetField("description_es", e.target.value)
              }
              maxCount={500}
              counterMode="character"
              enableCounter
              required
            />
            {categories && (
              <>
                <Select
                  className="form-field"
                  name="kind"
                  labelText="Tipo"
                  value={asset.kind}
                  onChange={(e) =>
                    updateAssetField("kind", parseInt(e.target.value))
                  }
                  required
                >
                  <SelectItem value={0} text={""} />
                  {categories.kind.map((k) => (
                    <SelectItem value={k.id} text={k.name} />
                  ))}
                </Select>
                <Select
                  className="form-field"
                  name="industry"
                  labelText="Indústria"
                  value={asset.industry}
                  onChange={(e) =>
                    updateAssetField("industry", parseInt(e.target.value))
                  }
                  required
                >
                  <SelectItem value={0} text={""} />
                  {categories.industry.map((i) => (
                    <SelectItem value={i.id} text={i.name} />
                  ))}
                </Select>
                <Select
                  className="form-field"
                  name="sales_play"
                  labelText="Sales Play"
                  value={asset.sales_play}
                  onChange={(e) =>
                    updateAssetField("sales_play", parseInt(e.target.value))
                  }
                  required
                >
                  <SelectItem value={0} text={""} />
                  {categories.sales_play.map((sp) => (
                    <SelectItem value={sp.id} text={sp.name} />
                  ))}
                </Select>
                <MultiSelect
                  className="form-field"
                  items={categories.ibm_tech}
                  titleText="Produtos"
                  itemToString={(e) => e.name}
                  onChange={(e) =>
                    updateAssetField(
                      "ibm_tech",
                      e.selectedItems.map((item) => item.id)
                    )
                  }
                />
                <MultiSelect
                  className="form-field"
                  items={categories.category}
                  titleText="Categorias"
                  itemToString={(e) => e.name}
                  onChange={(e) =>
                    updateAssetField(
                      "category",
                      e.selectedItems.map((item) => item.id)
                    )
                  }
                />
              </>
            )}
            {users && (
              <FilterableMultiSelect
                className="form-field"
                items={users.filter((user) => user.roles.includes("adm")) ?? []}
                titleText="Autores"
                itemToString={(e) => `${e.name} (${e.email})` ?? e.email}
                onChange={(e) => updateAssetField("authors", e.selectedItems)}
              />
            )}
            <FileUploader
              accept={["image/*"]}
              buttonLabel="Escolher arquivo"
              filenameStatus="edit"
              labelTitle="Imagem"
              labelDescription={
                assetId === "new"
                  ? "Escolha a imagem de capa da solução"
                  : "Para manter a mesma imagem, deixe este campo vazio"
              }
              aria-required
              onChange={(e) => handleFileUpload(e, "image")}
              onDelete={() => handleRemoveFile("image")}
            />
            <FileUploader
              accept={["video/mp4", "video/x-m4v", "video/*"]}
              buttonLabel="Escolher arquivo"
              filenameStatus="edit"
              labelTitle="Vídeo"
              labelDescription={
                assetId === "new"
                  ? "Escolha o vídeo da solução"
                  : "Para manter o mesmo vídeo, deixe este campo vazio"
              }
              aria-required
              onChange={(e) => handleFileUpload(e, "video")}
              onDelete={() => handleRemoveFile("video")}
            />
            <FileUploader
              accept={[".md"]}
              buttonLabel="Escolher arquivo"
              filenameStatus="edit"
              labelTitle="Documentação"
              labelDescription={
                assetId === "new"
                  ? "Escolha o arquivo .md da documentação da solução"
                  : "Para manter a mesma documentação, deixe este campo vazio"
              }
              aria-required
              onChange={(e) => handleFileUpload(e, "documentation")}
              onDelete={() => handleRemoveFile("documentation")}
            />
            <FileUploader
              accept={[".zip"]}
              buttonLabel="Escolher arquivo"
              filenameStatus="edit"
              labelTitle="Código fonte"
              labelDescription={
                assetId === "new"
                  ? "Escolha o arquivo .zip contendo o código fonte da solução"
                  : "Para manter o mesmo código fonte, deixe este campo vazio"
              }
              aria-required
              onChange={(e) => handleFileUpload(e, "source")}
              onDelete={() => handleRemoveFile("source")}
            />
            <Checkbox
              id="checkbox"
              className="form-field"
              labelText="Visível"
              value={!asset.visible}
              defaultChecked={!asset.visible}
              onChange={(e) => updateAssetField("visible", !e.target.checked)}
            />
            <ButtonSet>
              {assetId !== "new" && (
                <Button kind="danger" onClick={handleRemove}>
                  Excluir
                </Button>
              )}

              <Button type="submit">
                {assetId === "new" ? "Cadastrar" : "Atualizar"}
              </Button>
            </ButtonSet>
          </Form>
        </Column>
      </Grid>
    </div>
  );
}
