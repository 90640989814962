import React, { useEffect, useState } from "react";

import { useGlobalState } from "../../hooks/globalState";
import { useNavigate } from "react-router-dom";

import "./style.css";

import Header from "../../Components/Header";
import carousel0 from "../../imgs/carousel0.png";
import carousel1 from "../../imgs/carousel1.png";
import carousel2 from "../../imgs/carousel2.png";
import assets from "../../imgs/assets.png";
import demos from "../../imgs/demos.png";
import activations from "../../imgs/activations.png";
import cloudBlocks from "../../imgs/cloudBlocks.png";
import IBM from "../../imgs/IBM.png";
import eveline from "../../imgs/Eveline.png";
import jose from "../../imgs/Jose.png";
import larissa from "../../imgs/Larissa.png";
import marcelo from "../../imgs/Marcelo.png";
import julia from "../../imgs/Julia.png";
import mayara from "../../imgs/Mayara.png";
import aline from "../../imgs/Aline.png";
import livia from "../../imgs/Livia.png";
import jefferson from "../../imgs/Jefferson.png";

import { Grid, Column, Button } from "@carbon/react";

import TabPC from "../../Components/Tabs";
import AccordionMobile from "../../Components/Accordion";
import { ArrowDown, ArrowRight } from "@carbon/icons-react";
import languageConfig from "../../helpers/languageConfig";

const team = [
  {
    image: eveline,
    name: "Eveline Guedes",
    position: "Studio Leader",
  },
  {
    image: jose,
    name: "José Alberto Barbosa",
    position: "Engagement Leader",
  },
  {
    image: larissa,
    name: "Larissa Fattori",
    position: "Experience Lead",
  },
  {
    image: marcelo,
    name: "Marcelo Costa",
    position: "Briefing Coordinator",
  },
  {
    image: julia,
    name: "Júlia Guerreiro",
    position: "Design Intern",
  },
  {
    image: mayara,
    name: "Mayara Lucy",
    position: "Development Intern",
  },
  {
    image: aline,
    name: "Aline Monteiro",
    position: "Development Intern",
  },
  {
    image: livia,
    name: "Livia Batista",
    position: "Concierge",
  },
  {
    image: jefferson,
    name: "Jefferson Dias",
    position: "IT Support",
  },
];

export default function Dash() {
  const navigate = useNavigate();
  const { isMobile, language } = useGlobalState();

  const [carouselPage, setCarouselPage] = useState(0);
  const [userDidScroll, setUserDidScroll] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    const element = document.querySelector("#scrollIndicator");
    if (window.scrollY === 0) {
      element.classList.add("moveUpDown");
      setUserDidScroll(false);

      window.onscroll = () => {
        element.classList.add("disappear");
        element.classList.remove("moveUpDown");
        setUserDidScroll(true);
      };
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      // Loop over the entries
      entries.forEach((entry) => {
        // If the element is visible
        if (entry.isIntersecting) {
          // Add the animation class
          if (entry.target.classList.contains("animateRight")) {
            entry.target.classList.remove("animateRight");
            entry.target.classList.add("animated-right");
          } else if (entry.target.classList.contains("animateLeft")) {
            entry.target.classList.remove("animateLeft");
            entry.target.classList.add("animated-left");
          }
        }
      });
    });
    const squares = document.querySelectorAll(".animateLeft, .animateRight");
    squares.forEach((element) => observer.observe(element));
  }, []);

  useEffect(() => {
    document.querySelector("#homeBlockHeaderImg").classList.add("appear");
    setTimeout(() => {
      setCarouselPage(carouselPage < 2 ? carouselPage + 1 : 0);
      document.querySelector("#homeBlockHeaderImg")
        ? document
            .querySelector("#homeBlockHeaderImg")
            .classList.remove("appear")
        : null;
    }, 5000);
  }, [carouselPage]);

  return (
    <div>
      <Header />
      <div id="homeContent">
        <div id="scrollIndicator">
          <ArrowDown size={36} />
        </div>
        <div className="homeBlock" id="gifHomeBlock">
          <img
            id="homeGif"
            src={
              isMobile
                ? "https://asset-portal-files.s3.us-south.cloud-object-storage.appdomain.cloud/homeGifMobile.gif"
                : "https://asset-portal-files.s3.us-south.cloud-object-storage.appdomain.cloud/homeGifDesktop.gif"
            }
          />
        </div>
        <div className="homeBlock animateLeft">
          <Grid fullWidth style={!isMobile ? { paddingBottom: "5rem" } : {}}>
            <Column sm={4} md={8} lg={4} xlg={4}>
              <div className="homeBlockHeaderTitle">
                IBM Innovation Studio São Paulo
              </div>
            </Column>
            <Column sm={4} md={8} lg={12} xlg={12}>
              <div className="homeBlockHeaderTopic">
                {languageConfig[language].aceleratingIdeasTopic}
              </div>
            </Column>
            <Column sm={4} md={8} lg={4} xlg={4}></Column>
            <Column sm={4} md={8} lg={8} xlg={8}>
              <div className="homeBlockHeaderTopic">
                {languageConfig[language].aceleratingIdeasText.map((string) => {
                  return <p>{string}</p>;
                })}
              </div>
              <div className="homeBlockHeaderImgContainer">
                <img
                  id="homeBlockHeaderImg"
                  src={[carousel0, carousel1, carousel2][carouselPage]}
                />
                <div id="carouselPages">
                  <div
                    id="page0"
                    className={
                      carouselPage == 0
                        ? "carouselPageSelected"
                        : "carouselPage"
                    }
                  ></div>
                  <div
                    id="page1"
                    className={
                      carouselPage == 1
                        ? "carouselPageSelected"
                        : "carouselPage"
                    }
                  ></div>
                  <div
                    id="page2"
                    className={
                      carouselPage == 2
                        ? "carouselPageSelected"
                        : "carouselPage"
                    }
                  ></div>
                </div>
              </div>
            </Column>
            <Column sm={4} md={8} lg={4} xlg={4}>
              <div className="homeBlockContentPsTitle">
                {languageConfig[language].aceleratingIdeasPSTitle}
              </div>
              <div className="homeBlockContentPsText">
                {languageConfig[language].aceleratingIdeasPSText.map(
                  (string) => (
                    <p>{string}</p>
                  )
                )}
              </div>
            </Column>
          </Grid>
        </div>
        {isMobile && <div className="spacer"></div>}
        <div className="homeBlock animateRight">
          <Grid fullWidth style={!isMobile ? { paddingBottom: "5rem" } : {}}>
            <Column sm={4} md={8} lg={4} xlg={4}>
              <div className="homeBlockHeaderTitle">
                {languageConfig[language].howWeDoItTitle}
              </div>
            </Column>
            <Column sm={4} md={8} lg={12} xlg={12}>
              <div className="homeBlockHeaderTopic">
                {languageConfig[language].howWeDoItTopic}
              </div>
            </Column>
            <Column sm={4} md={8} lg={4} xlg={4}>
              <div className="homeBlockHeaderDetails">
                {languageConfig[language].howWeDoItText}
              </div>
            </Column>
            <Column sm={4} md={8} lg={12} xlg={12}>
              {isMobile ? <AccordionMobile /> : <TabPC />}
            </Column>
          </Grid>
        </div>
        {isMobile && <div className="spacer"></div>}
        <div className="homeBlock animateLeft">
          <Grid fullWidth style={{ paddingBottom: "5rem" }}>
            <Column sm={4} md={8} lg={4} xlg={4}>
              <div className="homeBlockHeaderTitle">
                {languageConfig[language].whatWeDoTitle}
              </div>
            </Column>
            <Column sm={4} md={8} lg={12} xlg={12}>
              <div className="homeBlockHeaderTopic">
                {languageConfig[language].whatWeDoTopic}
              </div>
            </Column>
            <Column sm={4} md={8} lg={4} xlg={4}>
              <div className="homeBlockHeaderDetails">
                {languageConfig[language].whatWeDoText}
              </div>
            </Column>
            <Column sm={4} md={8} lg={12} xlg={12}>
              <Grid>
                <Column sm={4} md={8} lg={4} xlg={4}>
                  <div className="whatWeDoItem">
                    <div className="whatWeDoImgContainer">
                      <img src={assets} />
                    </div>
                    <div className="whatWeDoTitle">
                      {languageConfig[language].whatWeDoTopic1Title}
                    </div>
                    <div className="whatWeDoText">
                      {languageConfig[language].whatWeDoTopic1Text}
                    </div>
                  </div>
                </Column>
                <Column sm={4} md={8} lg={4} xlg={4}>
                  <div className="whatWeDoItem">
                    <div className="whatWeDoImgContainer">
                      <img src={demos} />
                    </div>
                    <div className="whatWeDoTitle">
                      {languageConfig[language].whatWeDoTopic2Title}
                    </div>
                    <div className="whatWeDoText">
                      {languageConfig[language].whatWeDoTopic2Text}
                    </div>
                  </div>
                </Column>
                <Column sm={4} md={8} lg={4} xlg={4}>
                  <div className="whatWeDoItem">
                    <div className="whatWeDoImgContainer">
                      <img src={activations} />
                    </div>
                    <div className="whatWeDoTitle">
                      {languageConfig[language].whatWeDoTopic3Title}
                    </div>
                    <div className="whatWeDoText">
                      {languageConfig[language].whatWeDoTopic3Text}
                    </div>
                  </div>
                </Column>
              </Grid>
            </Column>
            <Column sm={4} md={8} lg={16} xlg={16}>
              <Button
                renderIcon={ArrowRight}
                iconDescription="Add"
                onClick={() => {
                  navigate(`/${language}/catalog`);
                }}
              >
                {languageConfig[language].whatWeDoButton}
              </Button>
            </Column>
          </Grid>
        </div>
        <div className="homeBlock" id="blackHomeBlock">
          <Grid
            condensed
            fullWidth
            style={{ padding: "0", paddingBottom: "5rem" }}
          >
            <Column
              className="blackHomeBlockColumn"
              sm={4}
              md={8}
              lg={8}
              xlg={8}
            >
              <div id="blackHomeBlockImgContainer">
                <img src={cloudBlocks} />
              </div>
            </Column>
            <Column
              sm={4}
              md={8}
              lg={8}
              xlg={8}
              className="blackHomeBlockColumn"
              style={{
                backgroundColor: "#262626",
                marginTop: "1rem !important",
              }}
            >
              <div id="blackHomeBlockContent">
                <div
                  id={
                    isMobile ? "blackHomeBlockTextMobile" : "blackHomeBlockText"
                  }
                >
                  {languageConfig[language].blackHomeBlockText}
                </div>
                <Button
                  id="blackHomeBlockButton"
                  size="2xl"
                  onClick={() => navigate(`/${language}/catalog`)}
                >
                  {languageConfig[language].blackHomeBlockButton}
                </Button>
              </div>
            </Column>
          </Grid>
        </div>
        <div className="homeBlock animateRight">
          <Grid fullWidth style={{ paddingBottom: "5rem" }}>
            <Column sm={4} md={8} lg={4} xlg={4}>
              <div className="homeBlockHeaderTitle">
                {languageConfig[language].meetTheTeamTitle}
              </div>
            </Column>
            <Column sm={4} md={8} lg={12} xlg={12}>
              <div className="homeBlockHeaderTopic">
                {languageConfig[language].meetTheTeamTopic}
              </div>
            </Column>
            <Column sm={4} md={8} lg={4} xlg={4}>
              <div className="homeBlockHeaderDetails">
                {languageConfig[language].meetTheTeamText}
              </div>
            </Column>
            <Column sm={4} md={8} lg={12} xlg={12}>
              <div id="teamGridContainer">
                <div id="teamGridText">
                  {languageConfig[language].meetTheTeamGridTitle}
                </div>
                <div id={isMobile ? "teamGridMobile" : "teamGrid"}>
                  {team.map((member, index) => (
                    <div
                      className={isMobile ? "teamMemberMobile" : "teamMember"}
                    >
                      <img src={member.image} />
                      <div className="teamMemberData">
                        <div className="teamMemberName">{member.name}</div>
                        <div className="teamMemberPosition">
                          {member.position}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </Column>
            <Column sm={4} md={8} lg={16} xlg={16}>
              <Button renderIcon={ArrowRight} iconDescription="Add">
                {languageConfig[language].meetTheTeamButton}
              </Button>
            </Column>
          </Grid>
        </div>
      </div>
    </div>
  );
}
