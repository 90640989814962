import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from "./Pages/Home/index.js";
import Catalog from "./Pages/Catalog/index.js";
import Asset from "./Pages/Asset/index.js";
import TechnicalDoc from "./Pages/TechnicalDoc/index.js";
import Register from "./Pages/Register/index.js";
import Groups from "./Pages/Groups/index.js";

import PageOutlet from "./Outlets/Outlet.js";
import ProtectedPage from "./Outlets/ProtectedPage.js";

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PageOutlet />}>
          <Route exact path="/" element={<Navigate to={"/pt/home"} />} />
          <Route path="/:lang/home" element={<Home />} />
          <Route element={<ProtectedPage />}>
            <Route path="/:lang/catalog" element={<Catalog />} />
            <Route path="/:lang/groups" element={<Groups />} />
            <Route path="/:lang/asset/:assetName" element={<Asset />} />
            <Route
              path="/:lang/asset/:assetName/doc"
              element={<TechnicalDoc />}
            />
            <Route path="/:lang/register/:assetId" element={<Register />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
