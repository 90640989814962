import React, { useEffect, useState } from "react";

import { useGlobalState } from "../../hooks/globalState";
import { useNavigate, useParams } from "react-router-dom";

import Header from "../../Components/Header";

import languageConfig from "../../helpers/languageConfig";

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Column,
  Grid,
  Tag,
} from "@carbon/react";
import { ArrowRight } from "@carbon/icons-react";
import { markdownKeys, types } from "../../helpers/helpers";

import "./style.css";

export default function Asset() {
  const navigate = useNavigate();
  const { assetName } = useParams();

  const { isMobile, language, assets, categories } = useGlobalState();

  const [asset, setAsset] = useState(null);

  const [markdown, setMarkdown] = useState("");
  const [category, setCategory] = useState([]);
  const [industry, setIndustry] = useState({ name: "", id: 0 });
  const [salesplay, setSalesplay] = useState({ name: "", id: 0 });
  const [technologies, setTechnologies] = useState([]);
  const [type, setType] = useState({ name: "", id: 0 });

  useEffect(() => {
    if (asset && categories) {
      setCategory(
        categories.category.filter((c) => asset.category.includes(c.id))
      );
      setTechnologies(
        categories.ibm_tech.filter((c) => asset.ibm_tech.includes(c.id))
      );
      setIndustry(categories.industry.find((i) => asset.industry === i.id));
      setSalesplay(
        categories.sales_play.find((s) => asset.sales_play === s.id)
      );
      setType(categories.kind.find((k) => asset.kind === k.id));
      setMarkdown(asset[markdownKeys[language].documentationKey]);
    }
  }, [asset, categories]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    if (assets && assetName) {
      const foundAsset = assets.find((item) => item.name === assetName);
      if (foundAsset) setAsset(foundAsset);
    }
  }, [assets]);

  return (
    <div id="assetContent">
      <Header />
      <div className="assetBlock" id="firstAssetBlock">
        <div id="breadCrumb">
          <Breadcrumb noTrailingSlash>
            <BreadcrumbItem
              className="breadcrumbWhiteText"
              onClick={() => navigate(`/${language}/home`)}
            >
              Home
            </BreadcrumbItem>
            <BreadcrumbItem
              className="breadcrumbWhiteText"
              onClick={() => navigate(`/${language}/catalog`)}
            >
              Catalog
            </BreadcrumbItem>
            <BreadcrumbItem
              className="breadcrumbWhiteText"
              onClick={() => navigate(`/${language}/asset/${assetName}`)}
            >
              {assetName}
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <Grid
          style={{
            height: "100%",
            margin: 0,
            paddingTop: "4rem",
            paddingLeft: "1rem",
            paddingBottom: "2rem",
          }}
        >
          <Column sm={4} md={8} lg={10} xlg={10}>
            <p className="assetTitle">{assetName}</p>
          </Column>
          <Column sm={4} md={8} lg={6} xlg={6} style={{ marginRight: "0px" }}>
            <div id="categoryImgContainer">
              <img
                src={
                  types[
                    types.findIndex((item) =>
                      type.name.toLocaleLowerCase().includes(item.name)
                    )
                  ]?.image
                }
              />
            </div>
          </Column>
        </Grid>
      </div>
      <Grid id="assetGrid" style={{ padding: 0 }}>
        <Column
          sm={4}
          md={8}
          lg={4}
          xlg={4}
          style={{ backgroundColor: "white" }}
          className="assetColumn"
        >
          <div id="assetFirstColumnContent">
            <div id="assetFirstColumnTitle">
              {languageConfig[language].assetSideTitle}
            </div>
            <div id="assetFirstColumnInfo">
              <div className="assetFirstColumnInfoItem">
                <div className="assetFirstColumnInfoTitle">
                  {languageConfig[language].assetSideType}
                </div>
                <div className="assetFirstColumnInfoText">{type.name}</div>
              </div>
              <div className="assetFirstColumnInfoItem">
                <div className="assetFirstColumnInfoTitle">
                  {languageConfig[language].assetSideSalesPlay}
                </div>
                <div className="assetFirstColumnInfoText">{salesplay.name}</div>
              </div>
              <div className="assetFirstColumnInfoItem">
                <div className="assetFirstColumnInfoTitle">
                  {languageConfig[language].assetSideCategory}
                </div>
                <div className="assetFirstColumnInfoText">
                  {category.map((c) => (
                    <p>{c.name}</p>
                  ))}
                </div>
              </div>
              <div className="assetFirstColumnInfoItem">
                <div className="assetFirstColumnInfoTitle">
                  {languageConfig[language].assetSideIndustry}
                </div>
                <div className="assetFirstColumnInfoText">{industry.name}</div>
              </div>

              <div className="assetFirstColumnInfoTitle">
                {languageConfig[language].assetSideTechnologies}
              </div>
              <div id="assetFirstColumnTags">
                {technologies.map((technology) => (
                  <Tag type="blue">{technology.name}</Tag>
                ))}
              </div>
            </div>
            <div id="assetFirstColumnButton">
              <Button
                onClick={() => {
                  navigate(`/${language}/asset/${assetName}/doc`);
                }}
                renderIcon={ArrowRight}
                iconDescription="Add"
              >
                {languageConfig[language].assetSideButton}
              </Button>
            </div>
          </div>
        </Column>
        <Column
          sm={4}
          md={8}
          lg={12}
          xlg={12}
          style={{ backgroundColor: "#f4f4f4" }}
          className="assetColumn"
        >
          <div className={isMobile ? "assetDocMobile" : "assetDoc"}>
            <p>{asset && asset[markdownKeys[language].descriptionKey]}</p>
            {markdown && asset?.video && (
              <video preload="metadata" id="assetVideoPlayer" controls>
                <source src={asset?.video} />
              </video>
            )}
            <div id="archTitle"> {languageConfig[language].architecture}</div>
            <img
              id="assetArch"
              src={
                markdown?.match(
                  /(?<=## \*\*Ar[quch]{2}ite[c]*tur[ae]\*\*.*[\n\t ]*!\[img\]\().*(?=\))/gim
                )?.[0]
              }
            />
          </div>
        </Column>
      </Grid>
    </div>
  );
}
