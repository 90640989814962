import React, { useContext, createContext, useState, useEffect } from "react";

const GlobalStateContext = createContext({});

export default function GlobalStateProvider({ children }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 500);
  const [language, setLanguage] = useState("pt");
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState(null);

  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [categories, setCategories] = useState(null);

  const [users, setUsers] = useState(null);

  const [filteredAssets, setFilteredAssets] = useState([]);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);

  const [search, setSearch] = useState("");

  useEffect(() => {
    setIsMobile(window.innerWidth < 500);
  });

  return (
    <GlobalStateContext.Provider
      value={{
        loading,
        setLoading,
        isMobile,
        setIsMobile,
        language,
        setLanguage,
        assets,
        setAssets,
        user,
        setUser,
        categories,
        setCategories,
        users,
        setUsers,
        filteredAssets,
        setFilteredAssets,
        currentPage,
        setCurrentPage,
        itemsPerPage,
        setItemsPerPage,
        search,
        setSearch,
        isAdmin,
        setIsAdmin,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
}

export function useGlobalState() {
  const context = useContext(GlobalStateContext);

  if (!context) {
    throw new Error("useGlobalState must be used within a GlobalStateProvider");
  }

  return context;
}
