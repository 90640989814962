import React from "react";

import { useGlobalState } from "../../hooks/globalState";
import { useNavigate } from "react-router-dom";
import { Select, SelectItem, Column } from "@carbon/react";

import "./style.css";

import IBM from "../../imgs/IBM.png";

export default function Footer() {
  const navigate = useNavigate();
  const { isMobile, language, setLanguage } = useGlobalState();

  return (
    <div id={isMobile ? "mobileFooter" : "footer"}>
      <img src={IBM} />
      <Select
        id="language-select"
        noLabel
        helperText=""
        onChange={(e) => {
          setLanguage((oldLang) => {
            navigate(
              location.pathname.replace(`/${oldLang}/`, `/${e.target.value}/`)
            );
            return e.target.value;
          });
        }}
        value={language}
      >
        <SelectItem value="pt" text="Portuguese (Brasil)" />
        <SelectItem value="en" text="English" />
        <SelectItem value="es" text="Spanish" />
      </Select>
    </div>
  );
}
