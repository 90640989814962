import React, { useEffect, useState } from "react";

import { useGlobalState } from "../../hooks/globalState";
import { useNavigate, useParams } from "react-router-dom";

import Markdown from "react-markdown";

import Header from "../../Components/Header";
import languageConfig from "../../helpers/languageConfig";
import {
  Breadcrumb,
  BreadcrumbItem,
  Column,
  Grid,
  Button,
} from "@carbon/react";
import { markdownKeys } from "../../helpers/helpers";
import { checkAssetAccess } from "../../helpers/data";
import { sendNotification } from "../../Components/Notification";

import "./style.css";

export default function TechnicalDoc() {
  const navigate = useNavigate();
  const { assetName } = useParams();

  const { isMobile, language, assets, setLoading, user } = useGlobalState();

  const [markdown, setMarkdown] = useState("");

  const [summaryTopics, setSummaryTopics] = useState([
    {
      title: "",
      subtitles: [],
    },
  ]);

  const [accessAllowed, setAccessAllowed] = useState(false);
  const [asset, setAsset] = useState(null);

  async function accessTechnicalDoc() {
    setLoading(true);
    const allowed = await checkAssetAccess(asset.id, user.role).finally(() =>
      setLoading(false)
    );
    if (allowed) setAccessAllowed(true);
    else {
      sendNotification(
        languageConfig[language].accessDeniedNotification.title,
        languageConfig[language].accessDeniedNotification.description(assetName)
      );
      navigate(`/${language}/asset/${assetName}`);
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    if (asset && user && !accessAllowed) accessTechnicalDoc();
  }, [asset, user]);

  useEffect(() => {
    if (assets && assetName) {
      const foundAsset = assets.find((item) => item.name === assetName);
      if (foundAsset) {
        setAsset(foundAsset);
        setMarkdown(foundAsset[markdownKeys[language].documentationKey]);
      }
    }
  }, [language, assets]);

  useEffect(() => {
    const temp = [];
    const titles = document.querySelectorAll("h2");

    for (let [titleIndex, title] of titles.entries()) {
      const subtitles = document.querySelectorAll(
        `h2:nth-of-type(${titleIndex + 1}) ~ h3:not(h2:nth-of-type(${
          titleIndex + 2
        }) ~ h3)`
      );
      temp.push({
        title: titles[titleIndex].innerText,
        subtitles: [...subtitles].map((item) => item.innerText),
      });
    }
    setSummaryTopics(temp);
  }, [markdown]);

  return (
    <div id="techContent" style={isMobile ? { overflowX: "hidden" } : {}}>
      <Header />
      <Grid id="techGrid" style={{ padding: 0 }}>
        <Column
          sm={4}
          md={8}
          lg={4}
          xlg={4}
          style={
            isMobile
              ? {
                  backgroundColor: "#161616",
                  minHeight: "calc(50vh - 3rem)",
                  position: "relative",
                }
              : {
                  backgroundColor: "#161616",
                  minHeight: "calc(100vh - 3rem)",
                }
          }
          className="techColumn"
        >
          {isMobile && (
            <div id="breadCrumbTechMobile">
              <Breadcrumb noTrailingSlash>
                <BreadcrumbItem
                  className="breadcrumbWhiteText"
                  onClick={() => navigate(`/${language}/home`)}
                >
                  Home
                </BreadcrumbItem>
                <BreadcrumbItem
                  className="breadcrumbWhiteText"
                  onClick={() => navigate(`/${language}/catalog`)}
                >
                  Catalog
                </BreadcrumbItem>
                <BreadcrumbItem
                  className="breadcrumbWhiteText"
                  onClick={() => navigate(`/${language}/asset/${assetName}`)}
                >
                  {assetName}
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          )}
          <div
            id={
              isMobile
                ? "techFirstColumnContentMobile"
                : "techFirstColumnContent"
            }
            style={isMobile ? { paddingTop: "6rem" } : {}}
          >
            <div id="techFirstColumnTitleContainer">
              <div id="techFirstColumnText">
                {languageConfig[language].docTitle}
              </div>
              <div id="techFirstColumnTitle">{assetName}</div>
              <div id="techFirstColumnLine"></div>
            </div>
            {summaryTopics?.map((topic, index) => (
              <div
                id={`techFirstColumnTopic-${index}`}
                className={
                  index === 0
                    ? "techFirstColumnTopic selected"
                    : "techFirstColumnTopic"
                }
                onClick={() => {
                  document
                    .querySelector(`.selected`)
                    ?.classList.remove("selected");
                  document
                    .querySelector(`#techFirstColumnTopic-${index}`)
                    ?.classList.add("selected");
                  [
                    ...document.querySelectorAll(`.techFirstColumnChild`),
                  ].forEach((element) => element?.classList.remove("bold"));
                  document
                    .querySelector(`#techFirstColumnChild-${index}-${0}`)
                    ?.classList.add("bold");

                  document
                    .querySelector(`h2:nth-of-type(${index + 1})`)
                    ?.scrollIntoView({ behavior: "smooth", block: "start" });
                }}
              >
                <div
                  id={`techFirstColumnTopicTitle-${index}`}
                  className="techFirstColumnTopicTitle"
                >
                  {topic?.title}
                </div>
                {topic?.subtitles?.map((subtitle, subIndex) => (
                  <div
                    id={`techFirstColumnChild-${index}-${subIndex}`}
                    className="techFirstColumnChild"
                    onClick={(e) => {
                      e.stopPropagation();
                      document
                        .querySelector(`.selected`)
                        ?.classList.remove("selected");
                      document
                        .querySelector(`#techFirstColumnTopic-${index}`)
                        ?.classList.add("selected");
                      [
                        ...document.querySelectorAll(`.techFirstColumnChild`),
                      ].forEach((element) => element?.classList.remove("bold"));
                      document
                        .querySelector(
                          `#techFirstColumnChild-${index}-${subIndex}`
                        )
                        ?.classList.add("bold");

                      summaryTopics.reduce((acc, item, itemIndex) => {
                        if (itemIndex <= index) {
                          acc += item.subtitles.length;
                        }
                        return acc;
                      }, 0) -
                        (subIndex + 1);

                      document
                        .querySelector(
                          `h3:nth-of-type(${
                            summaryTopics.reduce((acc, item, itemIndex) => {
                              if (itemIndex <= index) {
                                acc += item.subtitles.length;
                              }
                              return acc;
                            }, 0) -
                            summaryTopics[index].subtitles.length +
                            subIndex +
                            1
                          })`
                        )
                        ?.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    {subtitle}
                  </div>
                ))}
              </div>
            ))}
            <Button
              onClick={() => document.getElementById("downloadDoc").click()}
            >
              {languageConfig[language].codeDownload}
            </Button>
          </div>
        </Column>
        <Column
          sm={4}
          md={8}
          lg={12}
          xlg={12}
          style={{ backgroundColor: "#f4f4f4", position: "relative" }}
          className="techColumn"
        >
          {!isMobile && (
            <div id="breadCrumbTech">
              <Breadcrumb noTrailingSlash>
                <BreadcrumbItem
                  className="breadcrumbBlackText"
                  onClick={() => navigate(`/${language}/home`)}
                >
                  Home
                </BreadcrumbItem>
                <BreadcrumbItem
                  className="breadcrumbBlackText"
                  onClick={() => navigate(`/${language}/catalog`)}
                >
                  Catalog
                </BreadcrumbItem>
                <BreadcrumbItem
                  className="breadcrumbBlackText"
                  onClick={() => navigate(`/${language}/asset/${assetName}`)}
                >
                  {assetName}
                </BreadcrumbItem>
                <BreadcrumbItem
                  className="breadcrumbBlackText"
                  onClick={() =>
                    navigate(`/${language}/asset/${assetName}/doc`)
                  }
                >
                  Doc
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          )}
          <div className={isMobile ? "techDocMobile" : "techDoc"}>
            <Markdown>{markdown}</Markdown>
          </div>
        </Column>
      </Grid>
      <a href={asset?.source} target="_blank" id="downloadDoc" />
    </div>
  );
}
