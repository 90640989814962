import React, { useEffect, useState } from "react";

import { useGlobalState } from "../../hooks/globalState";
import Header from "../../Components/Header";

import {
  Grid,
  Column,
  Button,
  ListItem,
  Link,
  Form,
  TextInput,
  FilterableMultiSelect,
} from "@carbon/react";
import NewUserModal from "../../Components/NewUserModal";
import { getRoles } from "../../helpers/data";
import api from "../../services/api";
import "./style.css";

export default function Groups() {
  const { setLoading, users, assets } = useGlobalState();
  const defaultGroup = { name: "", users: [], assets: "" };
  const [formId, setFormId] = useState(0);

  const [groups, setGroups] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(defaultGroup);

  const [addNewUser, setAddNewUser] = useState(false);
  const [name, setName] = useState(selectedGroup.name);
  const [selectedAssets, setSelectedAssets] = useState(
    selectedGroup.assets.split(",")
  );
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [initialAssets, setInitialAssets] = useState([]);

  useEffect(() => {
    setLoading(true);
    getRoles(groups, setGroups).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (groups && groups.length > 0) {
      setSelectedGroup(groups[0]);
      setFormId(Math.random().toString());
    }
  }, [groups]);

  useEffect(() => {
    setName(selectedGroup.name);
    if (assets) {
      setInitialAssets(handleInitialAssets(selectedGroup.assets.split(",")));
      setSelectedAssets(selectedGroup.assets.split(","));
    }
  }, [selectedGroup, assets]);

  useEffect(() => {
    setSelectedUsers(
      users?.filter(
        (user) => user.roles.length > 0 && user.roles[0] === selectedGroup.name
      )
    );
    setFormId(Math.random().toString());
  }, [selectedGroup, users]);

  function onClick(group) {
    setSelectedGroup(group);
    setFormId(Math.random().toString());
  }

  async function handleRemove() {
    setLoading(true);
    await api.post("appid/deleteGroup", {
      id: selectedGroup.id,
    });
    let tmp = groups.filter((g) => g.id !== selectedGroup.id);
    setGroups(tmp);
    setSelectedGroup(tmp[0]);
    setFormId(Math.random().toString());
    setLoading(false);
  }

  async function handleSubmit() {
    setLoading(true);
    let id = selectedGroup.id ? selectedGroup.id : null;
    let groupData = {
      id,
      name,
      assets: selectedAssets.join(","),
    };
    let group = await api.post("appid/createGroup", groupData);
    id = group.data.id;
    await api.post("appid/assignUsers", {
      roleId: id,
      users: selectedUsers,
    });
    let tmp = { id: group.data.id, name, assets: selectedAssets.join(",") };
    setSelectedGroup(tmp);
    setGroups([...groups.filter((g) => g.id !== id), tmp]);
    setFormId(Math.random().toString());
    setLoading(false);
  }

  function handleInitialAssets(assetsTmp) {
    let initial = [];
    if (assetsTmp.length > 0 && assetsTmp[0] === "all")
      initial.push({ name: "all" });
    for (let asset of assets) {
      if (assetsTmp.some((id) => id === asset.id) || assetsTmp[0] === "all") {
        initial.push(asset);
      }
    }
    return initial;
  }
  function handleAssets(assets) {
    let newAssets = [];
    for (let asset of assets) {
      if (asset.name === "all") newAssets.push("all");
      else newAssets.push(asset.id);
    }
    setSelectedAssets(newAssets);
  }

  return (
    <div>
      <Header />
      <div className="content">
        <Grid
          style={{
            height: "100%",
            margin: 0,
            paddingTop: "4rem",
            paddingLeft: "1rem",
            paddingBottom: "2rem",
            backgroundColor: "#262626",
          }}
        >
          <Column sm={4} md={8} lg={10} xlg={10}>
            <p className="assetTitle">Grupos de Acesso</p>
          </Column>
        </Grid>
      </div>
      {groups && (
        <div id="content_groups">
          <div id="main_groups">
            <div id="groups">
              <fieldset className="bx--fieldset" required>
                {groups.map((group) => (
                  <ListItem
                    onClick={() => {
                      onClick(group);
                    }}
                  >
                    <a>{group.name}</a>
                  </ListItem>
                ))}
              </fieldset>
              <Link onClick={() => onClick(defaultGroup)}>
                + Adicionar novo Grupo
              </Link>
            </div>
          </div>
          <form id="form" key={formId}>
            <Form>
              <div style={{ marginBottom: "3rem" }}>
                <TextInput
                  labelText={"Nome"}
                  placeholder={"Insira o nome do grupo"}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div style={{ marginBottom: "3rem" }} id="users-selector">
                <FilterableMultiSelect
                  items={
                    users
                      ? users.filter(
                          (user) =>
                            user.roles.length === 0 ||
                            user.roles[0] === selectedGroup.name
                        )
                      : []
                  }
                  itemToString={(item) =>
                    item.name ? `${item.name} (${item.email})` : item.email
                  }
                  titleText={"Usuários"}
                  initialSelectedItems={selectedUsers}
                  onChange={({ selectedItems }) =>
                    setSelectedUsers(selectedItems)
                  }
                />
                <Link id="add-new-user" onClick={() => setAddNewUser(true)}>
                  + Adicionar novos usuários
                </Link>
              </div>
              <div style={{ marginBottom: "3rem" }}>
                <FilterableMultiSelect
                  items={
                    assets ? [{ name: "all" }, ...assets] : [{ name: "all" }]
                  }
                  initialSelectedItems={initialAssets}
                  itemToString={(item) => item.name}
                  label={"Assets disponibilizados"}
                  titleText={"Assets disponibilizados"}
                  onChange={({ selectedItems }) => handleAssets(selectedItems)}
                />
              </div>
              <div style={{ marginBottom: "3rem" }}>
                {selectedGroup.id ? (
                  <Button
                    id="danger-btn"
                    kind="danger"
                    style={{ marginTop: "0.5rem" }}
                    onClick={handleRemove}
                  >
                    Remover
                  </Button>
                ) : null}

                <Button
                  kind="primary"
                  style={{ marginTop: "0.5rem" }}
                  onClick={handleSubmit}
                >
                  {selectedGroup.id ? "Atualizar" : "Cadastrar"}
                </Button>
              </div>
            </Form>
          </form>
        </div>
      )}
      <NewUserModal open={addNewUser} setOpen={setAddNewUser} />
    </div>
  );
}
