import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { Loading } from "@carbon/react";
import { useGlobalState } from "../hooks/globalState";
import { getAssets, getCategories, getUsers } from "../helpers/data";
import Footer from "../Components/Footer";
import Notification from "../Components/Notification";

const PageOutlet = () => {
  const {
    loading,
    setLoading,
    setLanguage,
    assets,
    setAssets,
    categories,
    setCategories,
    setFilteredAssets,
    users,
    setUsers,
  } = useGlobalState();

  const { lang } = useParams();

  useEffect(() => {
    if (lang) setLanguage(lang);
    loadData();
  }, []);

  function loadData() {
    setLoading(true);
    Promise.all([
      getAssets(assets, setAssets, setFilteredAssets),
      getCategories(categories, setCategories),
    ]).finally(() => setLoading(false));
    getUsers(users, setUsers);
  }
  return (
    <>
      <Loading active={loading} />
      <Outlet />
      <Footer />
      <Notification />
    </>
  );
};

export default PageOutlet;
