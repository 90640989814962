import React, { useState, useEffect } from "react";

import { ToastNotification } from "@carbon/react";

import "./style.css";
let setTitleFunc, setCaptionFunc, setKindFunc, setShowFunc;

export function sendNotification(title, caption, kind = "error") {
  setTitleFunc(title);
  setCaptionFunc(caption);
  setKindFunc(kind);
  setShowFunc(true);
}

export default function Notification() {
  const [title, setTitle] = useState("");
  const [kind, setKind] = useState("");
  const [caption, setCaption] = useState("");

  const [show, setShow] = useState(false);

  setTitleFunc = setTitle;
  setCaptionFunc = setCaption;
  setKindFunc = setKind;
  setShowFunc = setShow;

  useEffect(() => {
    return () => {
      setTitleFunc = null;
      setCaptionFunc = null;
      setKindFunc = null;
      setShowFunc = null;
    };
  }, []);

  return (
    show && (
      <ToastNotification
        id="notification"
        caption={caption}
        timeout={4900}
        onClose={() => {
          setShow(false);
        }}
        kind={kind}
        onCloseButtonClick={() => {
          setShow(false);
        }}
        title={title}
      />
    )
  );
}
