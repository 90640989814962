import React from "react";

import {
  Heading,
  Theme,
  Button,
  InlineLoading,
  Grid,
  Column,
} from "@carbon/react";

import Header from "../Header";
import { useGlobalState } from "../../hooks/globalState";

import { ArrowRight } from "@carbon/icons-react";

import texts from "../../helpers/languageConfig";

import "./style.scss";
export default function Login({ loadingLogin, login }) {
  const { language } = useGlobalState();

  return (
    <Theme theme="white">
      <Header />
      <div id="login">
        <Grid id="login-grid">
          <Column sm={4} md={8} lg={6} className="content login-column">
            <Heading>Log in to IBM</Heading>
            <div className="divider" />
            <p>{texts[language].login}</p>
            <Button
              renderIcon={loadingLogin ? InlineLoading : ArrowRight}
              onClick={login}
            >
              Continuar
            </Button>
          </Column>
        </Grid>
      </div>
    </Theme>
  );
}
